html {
    background-color: #FFFFFF;
    box-sizing: border-box;
}

body {
    font-family: 'Lato', sans-serif;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        padding: 0 20px;
       }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}

h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    line-height: 72px;
    margin-bottom: 72px;
}

h1 .thin {
    font-weight: 300;
}

h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 62px;
    margin-bottom: 25px;
}

p {
    font-size: 16px;
    line-height: 30px;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}


/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
    background-color: transparent;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }


.site-logo {
    font-size: 0px;
    background-image: url(../image/logo.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    height: 35px;
    max-width: 100%;
    display: block;
}

.logo-wrapper {
    padding-top: 25px;
    padding-bottom: 22px;
}

.site-header p,
.nav-wrapper a {
    color: #3B4D30;
}

.site-header-inner {
    background: linear-gradient(144.7deg, #F8F8F8 13.27%, #EBFDE0 79.27%);
    border-radius: 29px;
    position: relative;
    padding: 45px 60px 75px;
}

.header-image {
    position: absolute;
    right: 0;
    bottom: 0;
    background-image: url('../image/tourists1.png');
    background-repeat: no-repeat;
    width: 420px;
    height: 518px;
}

.header-description {
    margin-bottom: 60px;
}

.nav-wrapper p {
    margin-bottom: 54px;
    letter-spacing: 0.345em;
}

.nav-wrapper ul {
    display: flex;
}

.nav-wrapper li {
    box-shadow: 0px 4px 33px 6px rgba(0, 0, 0, 0.1);
    width: 256px;
    height: 65px;
    border-radius: 50px;
    margin-right: 31px;
}

.nav-wrapper a {
    box-shadow: inset 0 0 0 0 #8DCA65;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 0.4s;
    display: block;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
    text-indent: 67px;
    line-height: 65px;
    border: 0;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50px;
    margin-bottom: 58px;
    position: relative;
}

.nav-wrapper a:hover {
    opacity: 1;
    background-color: #8DCA65;
    color: #FAFAFA;
    transition: all 0.2s linear;
    cursor: pointer;
    box-shadow: inset 0 0 0 50px #8DCA65;
}

.nav-wrapper a:before {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    top: 16px;
    left: 20px;
}

.nav-wrapper li:nth-of-type(1) a:before {
    background-image: url('../image/gift-box.png');
}

.nav-wrapper li:hover:nth-of-type(1) a:before {
    background-image: url('../image/gift-box-white.png');
}

.nav-wrapper li:nth-of-type(2) a:before {
    background-image: url('../image/about.png');
}

.nav-wrapper li:hover:nth-of-type(2) a:before {
    background-image: url('../image/about-white.png');
}

section p {
    color: #3B4C30;
    margin-bottom: 18px;
}

.team,
.service {
    display: flex;
    justify-content: space-between;
}

.service {
    padding-top: 56px;
}

.service .content {
    width: 40%;
    padding-right: 70px;
}

.service .table {
    width: 60%;
    padding-left: 5px;
    padding-top: 24px;
}

.table table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.table table th {
    color: #8E8E8E;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 50px;
    height: 56px;
}

.table table td {
    font-size: 18px;
}

.table table tr td:first-child,
.table table tr th:first-child {
    padding-left: 56px;
}

.table table tr td:first-child {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    font-weight: 900;
    color: #47573D;
}

.table table tr td:last-child {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.table table tr:nth-child(even) td {
    background-color: #FFF;
    height: 75px;
    line-height: 75px;
}

.table table tr:nth-child(odd) td {
    background-color: #F5F5F5;
    height: 65px;
    line-height: 65px;
}

.team {
    padding-top: 40px;
}

.team h2 {
    margin-top: 46px;
}

.team .visualization > div {
    width: 568px;
    height: 672px;
    background-image: url('../image/tourists2.png');
    background-repeat: no-repeat;
}

.team .content {
    padding-left: 76px
}

.team .content p {
    margin-bottom: 30px;
}

.contacts {
    text-align: center;
    background-color: #040603;
    padding-top:42px;
    padding-bottom: 70px;
    border-radius: 20px;
}

.contacts h2 {
    color: #FFF;
}

.contacts p {
    color: #D4E4C9;
}

.contacts a {
    text-decoration: underline;
    color: #8dca65;
}

footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn2 {
    display: block;
    font-size: 16px;
    font-style: normal;
    text-decoration: none;
    line-height: 65px;
    height: 65px;
    border: 0;
    background-color: #8DCA65;
    color: #FAFAFA;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    font-weight: bold;
    margin-top: 42px;
}

.btn2:hover {
    cursor: not-allowed;
}

.tooltip-bubble {    
    position: absolute;
    background: #3B4C30;
    padding: 20px 30px;
    font-size: 16px;
    text-shadow: 0px 1px 1px #000;
    color: #ecfde2;
    border-radius: 10px;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
    left: -9999px;
    top: -9999px;
    transition: opacity .2s ease-in-out;
    animation: bumping 0.8s ease-in-out infinite alternate;
}

.tooltip-bubble:before {
    content : ' ';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 16px solid #3B4C30;
    position:absolute;
    bottom:-12px;
    left: 30px;
}


@keyframes bumping {
    0% {
        transform: translateY(4px); 
    }
    100% {
        transform: translateY(0px); 
    }
}

footer .copyright {
    color: #828C98;
    font-size: 14px;
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}

@media only screen and (max-width: 768px) {

    header, 
    article,
    footer {
        padding: 0px 22px;
    }

    h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 24px;
    }

    h2 {
        font-size: 28px;
        line-height: 36px;
    }

    .mobile-detector {
        display: none;
    }

    .m-visible {
        display: initial;
    }
    
    .m-hidden {
        display: none;
    }

    .header-image {
        display: none;
    }

    .site-header-inner {
        padding: 26px 20px 28px;
    }

    .header-description {
        margin-bottom: 26px;
    }

    .nav-wrapper p {
        margin-bottom: 20px;
    }

    .nav-wrapper ul {
        display: block;
    }

    .nav-wrapper ul li:not(:last-child) {
        margin-bottom: 16px;
    }

    .nav-wrapper li {
        width: 100%;
    }

    .team, .service {
        flex-direction: column;
    }

    .service .content,
    .service .table {
        width: 100%
    }

    .service {
        padding-top: 0px;
    }

    .service h2 {
        margin-top: 28px;
    }

    .service .content {
        padding-right: 0px;
    }

    .service .table {
        overflow-x: scroll;
    }

    .service .table .scroller {
        width: 600px;
    }

    .table table tr td:first-child, 
    .table table tr th:first-child {
        padding-left: 18px;
    }

    .team .content {
        order: 1;
    }

    .team .visualization {
        order: 2;    
    }

    .team .visualization > div {
        background-image: url('../image/tourists2-mobile.png');
        width: 284px;
        height: 336px;
        margin: 0px auto;
    }

    .team .content {
        padding: 0px;
    }

    .team h2 {
        margin-top: 0px;
    }

    .contacts {
       padding: 42px;
    }

    footer .container {
        flex-direction: column;
        padding-bottom: 24px;
    }
}